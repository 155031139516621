import { IRequestContext } from '@msdyn365-commerce/core';
import axios from 'axios';

type Profile = {
    email: string;
    properties?: {};
};

export const subscribeToList = async (context: IRequestContext, profile: Profile, listId?: string) => {
    const publicKey = context.app.config.heiKlaviyoPublicKey;
    if (!publicKey) {
        return;
    }

    const endpoint = `https://a.klaviyo.com/client/subscriptions/?company_id=${publicKey}`;
    const apiRev = context.app.config.heiKlaviyoApiRevision ?? '2024-05-15';
    if (!listId) {
        listId = context.app.config.heiKlaviyoDefaultList ?? 'Tvx3cs';
    }
    return axios.post(
        endpoint,
        {
            data: {
                type: 'subscription',
                attributes: {
                    profile: {
                        data: {
                            type: 'profile',
                            attributes: profile
                        }
                    }
                },
                relationships: {
                    list: {
                        data: {
                            type: 'list',
                            id: listId
                        }
                    }
                }
            }
        },
        {
            headers: {
                revision: apiRev
            }
        }
    );
};
